<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <div class="ml-2 text-white">
      <b>{{ nomeUnidade }}</b>
    </div>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <img 
        v-if="checarTela()"
        style="cursor: pointer"
        src="../../../../assets/images/icons/log-icon.png"
        alt="Logs"
        @click="irParaTelaLog()"
      >
      <dark-Toggler class="d-none d-lg-block"/>
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    DarkToggler,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      nomeUnidade: '',
    }
  },
  created() {
    const nomeHeader = JSON.parse(window.localStorage.getItem('userName')).nomeFantasia
    if (nomeHeader) {
      this.nomeUnidade = nomeHeader
    } else {
      this.nomeUnidade = JSON.parse(window.localStorage.getItem('userName')).unidade
    }
  },
  methods: {
    irParaTelaLog() {
      this.$router.push({ name: 'logs', query: { from: this.$route.fullPath } })
    },
    checarTela() {
      const rota = this.$route.name
      switch (rota) {
        case 'clientes-cadastro': return true
        case 'clientes-editar': return true
        case 'perfis-cadastro': return true
        case 'tarifas-cadastro': return true
        case 'usuarios-cadastro': return true
        case 'taxas-cadastro': return true
        case 'faturas-cadastro': return true
        case 'impostos-cadastro': return true
        case 'importar-movimento': return true
        default: return false
      }
    },
  },
}
</script>
